// 角色和权限相关接口
import request from '@/request/request'
// 获取公钥的接口
export const PublicKeyApi = () => request.get('/admin/sys/user/public/key');
// 获取当前用户权限菜单
export const UserPermissionApi = () => request.get('/admin/sys/user/permission');



// 角色列表接口
export const RoleListApi = (params) => request.post("/agent/admin/role/rolelist", params);
export const RoleoptionList = (params) => request.post("/agent/admin/role/roleoptionlist", params); // 勿删 用于过滤

// 树形列表接口
export const TreeListApi = () => request.post('/agent/admin/menu/menutree');

// 新增角色接口
export const RoleAddApi = (params) => request.post("/agent/admin/role/addrole", params);

// 编辑角色接口
export const RoleUpdateApi = (params) => request.post(`/agent/admin/role/editrole`, params);

// 删除角色接口
export const RoleDeleteApi = (params) => request.post(`/agent/admin/role/deleterole`, params);

// 获取角色详情接口
export const RoleDetailApi = (params) => request.post(`/agent/admin/role/roleinfo`, params );

// 用户列表接口
export const userListApi = (params) => request.post(`/agent/admin/account/adminlist`, params);

// 新增用户接口
export const userAddApi = (params) => request.post(`/agent/admin/account/addadmin`, params);

// 编辑用户接口
export const userUpdateApi = (params) => request.post(`/agent/admin/account/editadmin`, params);

// 用户详情接口
export const userDetailApi = (params) => request.post(`/agent/admin/account/admindetail`, params);

// 禁用用户接口
export const userDisableApi = (params) => request.post(`/agent/admin/account/disable`, params);

// 启用用户接口
export const userEnableApi = (params) => request.post(`/agent/admin/account/enable`, params);

// 修改密码
export const EditPassword = (params) => request.post(`/agent/admin/account/editpassword`, params);
