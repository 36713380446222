<template>
  <!-- 添加人员页面 -->
  <div class="positionT0L0">
    <p class="fw600 marginB20">基本信息</p>
    <a-form-model ref="ruleForm" :model="form" :rules="rules" labelAlign="right" :label-col="{ span: 2 }" :wrapper-col="{ span: 5 }">
      <a-form-model-item label="姓名" prop="userName">
        <a-input v-model="form.userName" :maxLength="32" placeholder="请输入姓名">
          <div slot="addonAfter">{{ form.userName.length }}/32</div>
        </a-input>
      </a-form-model-item>
	  <a-form-model-item label="手机号码" prop="phone" required>
        <a-input v-model="form.phone" :maxLength="32" placeholder="请输入手机号码"/>
      </a-form-model-item>
      <a-form-model-item label="密码" prop="pwd" v-if="this.$route.params.id == '88'" required>
        <a-input-password v-model="form.pwd" :maxLength="32" placeholder="请输入密码" type="password"/>
      </a-form-model-item>
      <a-form-model-item label="确认密码" prop="confirmPwd" v-if="this.$route.params.id == '88'" required>
        <a-input-password v-model="form.confirmPwd" :maxLength="32" placeholder="请确认密码" type="password"/>
      </a-form-model-item>
      <a-form-model-item ref="remark" label="备注" prop="remark">
		<a-textarea v-model="form.remark" placeholder="请输入备注" allow-clear />
      </a-form-model-item>
      <p class="title marginB20">权限设置</p>
      <a-form-item label="角色">
        <a-checkbox-group @change="checkChang" :value="checkboxDefa">
          <a-checkbox :value="item.roleNo" v-for="item in roleList" :key="item.roleNo">
            {{ item.roleName }}
          </a-checkbox>
        </a-checkbox-group>
      </a-form-item>
      <FooterToolBar :collapsed="sideCollapsed">
        <a-button class="margin_right60" @click="()=>$router.go(-1)">返回</a-button>
        <a-button type="primary" @click="keepClick(form)" :disabled="isDisabled">保存</a-button>
      </FooterToolBar>
    </a-form-model>
  </div>
</template>

<script>
import { JSEncrypt } from "jsencrypt";
import { message } from "ant-design-vue";
import FooterToolBar from "@/components/FooterToolbar";
import { baseMixin } from "@/store/app-mixin";
import {
	RoleListApi,
	userAddApi,
	userUpdateApi,
	userDetailApi,
  	RoleoptionList
} from "@/request/api/rolePower";
export default {
    mixins: [baseMixin],
    components:{FooterToolBar},
  created() {
    this.id = this.$route.params.id;
    this.roleListFn();
    this.userDetailFn();
  },
  data() {
    return {
      id: "", // url带过来的id
      isDisabled: false, // 保存的按钮是否禁用
      // 角色列表
      roleList: [],
      checkboxDefa: [], //复选框默认选中的数组
      publicKey:
        "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDR9fKOSRTLAtABTQAuSljGQXkU0ZR3kpCsnxoiOfxEMCsuhJIKFKpxxDa0exnucGY5lS1g0QckvRODnxniiazNzOqNUv2z4G3VKpkXDUET2R17kjPchw6EaZZ4RZGzqhuRHj4vx/5Pxm3ET+j8gN3brlgeXjUzdM5gHTBw9uTXoQIDAQAB", // 密码加密的公钥
      form: {
        userName: "",
        pwd: "",
        confirmPwd: "",
        phone: "",
        remark: "",
      },
      rules: {
        userName: [{ message: "输入的内容不能为空", trigger: "blur" }], // 账号
        realName: [
          { required: true, message: "输入的内容不能为空", trigger: "blur"},
          { max: 32, message: "最大长度为32个字", trigger: "blur"},
        ],
        pwd: [{ validator: this.validatePwd, trigger: "blur" }], // 密码
        confirmPwd: [{ validator: this.validateConfirmPwd, trigger: "blur" }],
        phone: [{ validator: this.validatePhone, trigger: "blur" }],
      },
    };
  },
  methods: {
    // 复选框改变的回调
    checkChang(valueArr) {
		this.checkboxDefa = valueArr;
    },
    // 保存的按钮
    keepClick(form) {
      this.isDisabled = true;
      setTimeout(() => {
        this.isDisabled = false;
      }, 2000);
      let id = this.$route.params.id;
      this.$refs["ruleForm"].validate((result) => {
        if (result) {
          if (id === "88") {
            userAddApi({
              password: this.form.pwd,
              phone: this.form.phone,
              remark: this.form.remark,
              roleNos: this.checkboxDefa,
              name: this.form.userName,
            }).then(({ code, data }) => {
              if (code === 200) {
                message.info("保存成功");
                this.$router.push("/rolePower/personManage");
              }
            });
          } else {
            userUpdateApi({
              adminNo: this.id,
              phone: this.form.phone,
              remark: this.form.remark,
              roleNos: this.checkboxDefa,
              name: this.form.userName,
            }).then(({ code, data }) => {
              if (code === 200) {
                message.info("保存成功");
                this.$router.push("/rolePower/personManage");
              }
            });
          }
        }
      });
    },
    // 密码加密
    encryption(msg, key) {
      let encryptor = new JSEncrypt(); // 创建加密对象实例
      //之前ssl生成的公钥，复制的时候要小心不要有空格
      encryptor.setPublicKey(key); //设置公钥
      let rsaPassWord = encryptor.encrypt(msg); // 对内容进行加密
      return rsaPassWord;
    },
    // 账号的校验规则
    validateUserName(rule, value, callback) {
      let reg =
        /^[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*\.[a-z]{2,}$/;
      if (value === "") {
        callback("输入的内容不能为空");
      } else if (!reg.test(value)) {
        callback("请输入正确的邮箱地址");
      } else {
        callback();
      }
    },
    // 密码校验规则
    validatePwd(rule, value, callback) {
      let reg = /(?!^[0-9]+$)(?!^[A-z]+$)(?!^[^A-z0-9]+$)^[^\s\u4e00-\u9fa5]{8,16}$/;
    //   const reg = /(?!^[0-9A-Z]+$)(?!^[0-9a-z]+$)(?!^[A-z]+$)(?!^[^A-z0-9]+$)^[^\s\u4e00-\u9fa5]{8,16}$/;
      if (value === "") {
        callback("输入的内容不能为空");
      } else if (!reg.test(value)) {
        callback(
          "请输入8-16位密码,数字,字母,字符至少包含两种,不能包含中文和空格"
        //   "请输入8-16位字母+数字组合,必须含有大小写字母"
        );
      } else {
        callback();
      }
    },
    // 确认密码校验规则
    validateConfirmPwd(rule, value, callback) {
      if (value == "") {
        callback("输入的内容不能为空");
      } else if (value != this.form.pwd) {
        callback("和上面密码不一致, 请重新输入");
      } else {
        callback();
      }
    },
    // 手机号校验规则
    validatePhone(rule, value, callback) {
      let reg = /^1(2|3|4|5|6|7|8|9)\d{9}$/;
      if (value === "") {
        callback("输入的内容不能为空");
      } else if (!reg.test(value)) {
        callback("请输入正确的手机号");
      } else {
        callback();
      }
    },
    // 封装请求人员列表函数
    roleListFn() {
		RoleoptionList({
			isAgent:0
		}).then(({ code, data }) => {
			if (code == 200) {
				this.roleList = data;
			}
		});
    },
    // 封装获取用户详情函数
    userDetailFn() {
		let id = this.$route.params.id;
		if (id !== "88") {
			userDetailApi({ adminNo: id }).then(({ code, data }) => {
				if (code == 200) {
					this.form.userName = data.name,
					this.form.phone = data.phone,
					this.form.remark = data.remark,
					this.checkboxDefa = data.rolsNos;
				}
			});
		}
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.ant-form-item-control {
  position: relative;
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
  margin-bottom: 10px;
}
/deep/.ant-checkbox + span {
  padding: 0 15px 10px 3px;
  // padding-left: 3px;
}
</style>
